<template>
  <ui-page :subtitle="$store.state.event.name" title="Dashboard">
    <ui-cards>
      <ui-card>
        <template v-slot:head>Quicklinks</template>
        <ul>
          <li>
            <a class="cursor-pointer" @click="openSettings()">Einstellungen</a>
          </li>
          <li>
            <a class="cursor-pointer" @click="openAttendees()">Teilnehmer</a>
          </li>
        </ul>
      </ui-card>
      <ui-card>
        <template v-slot:head>Statistiken</template>

      </ui-card>
    </ui-cards>
  </ui-page>
</template>

<script>

export default {
  name: "Dashboard",
  data(){
    return {

    }
  },
  methods: {
    openSettings(){
      this.$router.push({ name: 'AdminEventSettings', params: { id: this.$store.state.event.id } })
    },
    openAttendees(){
      this.$router.push({ name: 'AdminEventAttendees', params: { id: this.$store.state.event.id } })
    }
  }
}
</script>

<style scoped>

</style>
